const getPartnerDetailMappedSuccessStories = (detail, providerId, successStoriesId) => {
  const result = { ...detail };
  try {
    const successStories = detail?.success_stories.sort((a, b) => a.id - b.id) || [];
    const nextStory = successStories.filter((element) => (element.id > providerId)).filter((_, i) => i < 3);
    const nextLen = nextStory.length;
    const isPrevStory = nextLen < 3;
    const prevStoryGetCount = () => {
      if (nextLen === 1) { return 2; }
      if (nextLen === 2) { return 1; }
      return 3;
    };
    const prevStory = successStories.sort((a, b) => b.id - a.id).filter((ele) => ele.id < providerId).filter((_, i) => (isPrevStory ? i < prevStoryGetCount() : i < 3));
    let stories = [];
    if (nextLen !== 3) {
      stories = [...prevStory, ...nextStory];
    } else {
      stories = [...nextStory];
    }
    result.moreSuccessStories = stories.filter((item) => item.id !== successStoriesId);
  } catch {
    result.moreSuccessStories = [];
  }
  return result;
};
module.exports = {
  getPartnerDetailMappedSuccessStories,
};
