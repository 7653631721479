import loadable from '@loadable/component';
import {
  COMPONENT_INDEX, COMPONENT_PROFILE,
  COMPONENT_NOTFOUND,
  COMPONENT_SUCCESS_STORIES,
} from './constants';

const importMap = {};

/* Enable Async module loading and code splitting for client */
importMap[COMPONENT_INDEX] = loadable(() => import(/* webpackChunkName: "index" */'../pageTypes/index'));
importMap[COMPONENT_PROFILE] = loadable(() => import(/* webpackChunkName: "index" */'../pageTypes/profile'));
importMap[COMPONENT_SUCCESS_STORIES] = loadable(() => import(/* webpackChunkName: "index" */'../pageTypes/success-stories'));
importMap[COMPONENT_NOTFOUND] = loadable(() => import(/* webpackChunkName: "notFound" */'../pageTypes/notFound'));

export default importMap;
