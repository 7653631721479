/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import window from 'global/window';
import { load } from 'cheerio';
import logger from './logger';
import {
  ENVIRONMENT_CONSTANTS,
  PRELOADED_STATE_KEY,
  AWS_ENV_VARS,
} from './constants';
import { makeSingleLangTranslatedData } from '../utils/srp.util';
import getTranslatedText from '../services/translate.service';

export const getEnvironmentConstant = ({ key, env }) => {
  if (ENVIRONMENT_CONSTANTS[env] && ENVIRONMENT_CONSTANTS[env][key]) {
    const defaultValue = ENVIRONMENT_CONSTANTS[env][key];
    if (typeof window[PRELOADED_STATE_KEY]?.envData?.[key] !== 'undefined') {
      return window[PRELOADED_STATE_KEY]?.envData[key];
    }
    if (typeof process.env[key] !== 'undefined') {
      // Add Validation
      if (AWS_ENV_VARS[key]) {
        const processEnv = `${process.env[key]}`;
        if (AWS_ENV_VARS[key].default || processEnv) { // If not set and no default available hide the key
          if (typeof AWS_ENV_VARS[key].validator === 'function') {
            return AWS_ENV_VARS[key].validator(processEnv) ? processEnv : AWS_ENV_VARS[key].default;
          }
          return processEnv || AWS_ENV_VARS[key].default;
        }
      }
      return `${process.env[key]}`;
    }
    return defaultValue;
  }
  logger.error(`${key} not found in environment constants config for env ${env}`);
  return null;
};

export const stripTrailingSlash = (str) => (str.endsWith('/') ? str.slice(0, -1) : str);

// Flatten array of arrays
export const flatDeep = (arr, d = 1) => (d > 0
  ? arr.reduce((acc, val) => acc.concat(Array.isArray(val)
    ? flatDeep(val, d - 1) : val), [])
  : arr.slice());

// Generate URN for LRS Producer API
export const generateURN = ({
  uid, verb, lrsId, type,
}) => `${uid}:${verb}:urn:${type}:${lrsId}`;

export const getInnerText = (obj) => {
  if (obj?.props?.children) {
    if (Array.isArray(obj.props.children)) {
      const map = obj.props.children.map((child) => {
        if (child?.props?.children) {
          return getInnerText(child);
        }
        if (typeof child === 'string') {
          return child;
        }
        // If child is an object without string children eg <br/> then return empty string
        return '';
      });
      return map.filter((str) => str !== '').join(' ');
    }
    return obj.props.children;
  }
  return obj;
};

export const getDynamicTranslations = async (textsToTranslate, language) => {
  const translateDataArr = [];
  let activeChunk = [];
  let currentSize = 0;
  textsToTranslate.forEach((text) => {
    currentSize += text.source.length;
    if (currentSize > 5000) {
      translateDataArr.push(activeChunk);
      activeChunk = [text];
      currentSize = text.source.length;
    } else {
      activeChunk.push(text);
    }
  });
  if (activeChunk.length > 0) {
    translateDataArr.push(activeChunk);
  }
  let data = [];
  let payload = [];
  const promises = translateDataArr.map(async (translateDataChunk) => {
    const response = await getTranslatedText(translateDataChunk, language);
    data = [...data, ...response];
    payload = [...payload, ...translateDataChunk];
  });
  await Promise.all(promises);
  return makeSingleLangTranslatedData(data, payload, language);
};

export const getTextFromElement = (text) => {
  if (typeof DOMParser === 'undefined') {
    const $ = load(text);
    const textContent = $('body').text() || '';
    return textContent.trim();
  }
  const parser = new DOMParser();
  const parsedText = parser.parseFromString(text, 'text/html');
  const textContent = parsedText.body.textContent || parsedText.body.innerText || '';
  return textContent.trim();
};

export const modifyPartnerListing = (listing) => {
  const modifiedListing = { ...listing };
  if (!modifiedListing?.data?.partners?.length) {
    return listing;
  }
  modifiedListing.data.partners.forEach((element) => {
    element.headline = getTextFromElement(element.headline);
  });
  return modifiedListing;
};

export const modifyPartnerDetails = (details) => {
  const response = { ...details };
  response?.data?.success_stories?.forEach((element) => {
    element.customer_challenge = getTextFromElement(element.customer_challenge);
  });
  if (response?.data?.headline) {
    response.data.headline = getTextFromElement(response.data.headline);
  }
  return response;
};

window.getInnerText = getInnerText;

export const addI18ResourceBundle = async (state, i18n, page) => {
  const translatedData = await getDynamicTranslations(state.textToTranslate, i18n.language);
  i18n.addResourceBundle(i18n.language, page, translatedData[i18n.language], true, true);
};
