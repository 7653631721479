// Routes with subroutes possible see: https://reactrouter.com/web/example/route-config
// Note: subroute components would display on page WITH parent route component

import HomeLoadData from '../api/home';
import SuccessStoriesLoadData from '../api/success-stories';
import ProfileLoadData from '../api/profile';
import {
  COMPONENT_INDEX,
  COMPONENT_NOTFOUND,
  COMPONENT_PROFILE,
  COMPONENT_SUCCESS_STORIES,
  MAINTENANCE_MODE,
  BASENAME,
} from './constants';
// Common route definition used by SSR and CSR
export const defaultRoutes = [
  {
    path: `${BASENAME}`,
    componentKey: COMPONENT_INDEX,
    loadData: HomeLoadData,
    exact: true,
  },
  {
    path: `${BASENAME}/`,
    componentKey: COMPONENT_INDEX,
    loadData: HomeLoadData,
    exact: true,
  },
  {
    path: `${BASENAME}/:name/:id`,
    componentKey: COMPONENT_PROFILE,
    loadData: ProfileLoadData,
    exact: true,
  },
  {
    path: `${BASENAME}/success-stories/:name/:id`,
    componentKey: COMPONENT_SUCCESS_STORIES,
    loadData: SuccessStoriesLoadData,
    exact: true,
  },
  {
    path: `${BASENAME}/index.html`,
    componentKey: COMPONENT_INDEX,
    exact: true,
  },
  {
    path: `${BASENAME}*`,
    componentKey: COMPONENT_NOTFOUND,
    status: 404,
  },

];

export const maintenanceRoutes = [
  {
    path: '*',
    componentKey: MAINTENANCE_MODE,
    status: 200,
  },
];
