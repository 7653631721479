import { getUserCountry } from '../services/location.service';

const getCookie = (cName, cookieContext) => {
  let ca = '';
  if (typeof window !== 'undefined') {
    ca = document?.cookie.split(';');
  } else {
    ca = cookieContext?.split(';');
  }
  let cookieValue = '';
  ca.forEach((cookie) => {
    const c = cookie.replace(/^\s+|\s+$/gm, '');
    if (c.indexOf(cName) !== -1) cookieValue = c.substring(cName.length + 1, c.length);
  });

  return cookieValue;
};

const getUserCountryCookie = async (cookieContext = undefined) => {
  // if en-us, then first attempt at getting the
  // cookie "userCountryCode"
  if (cookieContext && getCookie('userCountryCode', cookieContext)) {
    return getCookie('userCountryCode', cookieContext);
  }

  // Then try other cookie
  // cookie "cdn-user-cc"
  if (cookieContext && getCookie('cdn-user-cc', cookieContext)) {
    return getCookie('cdn-user-cc', cookieContext);
  }

  // If not found in cookie, get IP location

  try {
    // const locationService = new LocationService();
    const userCountry = await getUserCountry();
    return userCountry;
  } catch (e) {
    console.error('Detecting IP location', e);
    return 'US';
  }
};

export default getUserCountryCookie;
