import { BEARER_TOKEN, ENVIRONMENT, ENVIRONMENT_CONSTANTS } from '../../common/constants';
import { ProfileStateInterface } from '../../common/context/profile/interface';
import { modifyPartnerDetails } from '../../common/utilities';
import { API_PATH } from '../../constants/api-constants';
import { READ } from '../../services/actionTypes';
import { getFormattedProfileData } from '../../utils/profile.util';

const {
  API_V1, PARTNER_DETAIL, PARTNERS_FILTER,
} = API_PATH;

export default async (ctx) => {
  const { url } = ctx.request;
  const env = process.env[ENVIRONMENT] || 'dev';
  const host = ENVIRONMENT_CONSTANTS[env].PARTNER_HOST;
  const partnerApiToken = process.env[BEARER_TOKEN];
  const header = {
    Authorization: `Bearer ${partnerApiToken}`,
  };

  const initialState = { ...ProfileStateInterface, errors: { error: false } };
  initialState.loading = false;
  const filterApi = READ(`${host}${API_V1}${PARTNERS_FILTER}`, {}, header);
  const detailsApi = READ(`${host}${API_V1}${PARTNER_DETAIL}${url?.split('/').pop()}`, {}, header);

  const promiseState = await Promise.all([filterApi, detailsApi]).then(async (result) => {
    const partnerAPIResponse = modifyPartnerDetails(result[1]);
    const response = getFormattedProfileData({ filterData: result[0]?.data, profileData: partnerAPIResponse?.data });
    return {
      ...initialState,
      partnerDetails: partnerAPIResponse?.data || {},
      profileDetails: response || {},
    };
  }).catch(() => ({
    ...initialState,
    errors: { error: true },
  }));
  promiseState.env = env;
  return promiseState;
};
