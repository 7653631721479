import { BEARER_TOKEN, ENVIRONMENT, ENVIRONMENT_CONSTANTS } from '../../common/constants';
import { SuccessStoriesStateInterface } from '../../common/context/success-stories/interface';
import { modifyPartnerDetails } from '../../common/utilities';
import { API_PATH } from '../../constants/api-constants';
import { READ } from '../../services/actionTypes';
import { getPartnerDetailMappedSuccessStories } from '../../utils/success-stories.util';

const {
  API_V1, PARTNERS_SUCCESS_STORIES, PARTNER_DETAIL,
} = API_PATH;
export default async (_, route) => {
  const { params } = route;
  const env = process.env[ENVIRONMENT] || 'dev';
  const host = ENVIRONMENT_CONSTANTS[env].PARTNER_HOST;
  const partnerApiToken = process.env[BEARER_TOKEN];
  const header = {
    Authorization: `Bearer ${partnerApiToken}`,
  };
  const initialState = {
    ...SuccessStoriesStateInterface,
    loading: true,
    errors: {
      storyError: true,
      message: '',
    },
  };
  if (params.id) {
    try {
      const response = await READ(`${host}${API_V1}${PARTNERS_SUCCESS_STORIES}/${params.id}`, {}, header);
      if (response.status === 200) {
        const successStories = response?.data || {};
        const resultData = { ...initialState };
        resultData.successStories = successStories;
        resultData.errors.storyError = false;
        resultData.loading = false;
        const res = await READ(`${host}${API_V1}${PARTNER_DETAIL}${successStories.provider_id}`, {}, header);
        if (res.status === 200) {
          const detailAPIRes = modifyPartnerDetails(res);
          resultData.partnerDetail = detailAPIRes?.data || {};
          const partnerDetail = getPartnerDetailMappedSuccessStories(resultData.partnerDetail, successStories.provider_id, successStories.id);
          resultData.partnerDetail = partnerDetail;
        }
        resultData.env = env;
        return resultData;
      }
      return initialState;
    } catch (error) {
      initialState.errors.storyError = true;
      initialState.loading = true;
      initialState.env = env;
      return initialState;
    }
  }
  initialState.env = env;
  return initialState;
};
