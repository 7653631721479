import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import window from 'global/window';
import { matchPath } from 'react-router';
import document from 'global/document';
import loadable from '@loadable/component';
import {
  PRELOADED_STATE_KEY, AWS_ENV_VARS, MAINTENANCE_MODE, ENVIRONMENT,
} from './app/common/constants';
import ClientComponentLoader from './app/common/clientComponentLoader';
import { EnvProvider } from './app/common/envContext';
import logger from './app/common/logger';

import { defaultRoutes, maintenanceRoutes } from './app/common/routerConfig';

const App = loadable(() => import(/* webpackChunkName: "App" */'./App'));
// eslint-disable-next-line no-underscore-dangle
const preLoadState = window.__PRELOADED_STATE__;
// eslint-disable-next-line no-underscore-dangle
delete window.__PRELOADED_STATE__;
let preloadedState = {};
// Grab the state from a global variable injected into the server-generated HTML
// eslint-disable-next-line no-underscore-dangle
preloadedState = window[PRELOADED_STATE_KEY];
const defaultEnvData = {};
Object.keys(AWS_ENV_VARS).forEach((key) => {
  defaultEnvData[key] = AWS_ENV_VARS[key].default;
});
const env = preloadedState?.envData || defaultEnvData;
env.COMPONENT_LOADER = ClientComponentLoader;
env[ENVIRONMENT] = preLoadState.env || 'dev';

// Allow this to be dynamic from env props
// eslint-disable-next-line camelcase,no-undef

logger.info('initializing app');

const routes = env[MAINTENANCE_MODE] ? maintenanceRoutes : defaultRoutes;

const hydrateApplication = async () => {
  await hydrate(
    <HelmetProvider>
      <BrowserRouter>
        <EnvProvider value={env}>
          <Suspense fallback={<div>Loading...</div>}>
            <App store={preLoadState} />
          </Suspense>
        </EnvProvider>
      </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root'),
  );
};

const filtered = routes.filter((route) => matchPath(document.location.pathname, {
  path: route.path,
  exact: route.exact || false,
  strict: route.strict || false,
}) !== null);
if (filtered.length) {
  // Found a matching component for the route.  Wait for it to load before attempting hydration
  logger.info('Preloading component: ', filtered[0].componentKey);
  ClientComponentLoader[filtered[0].componentKey].load().then(() => {
    hydrateApplication();
  });
} else {
  hydrateApplication();
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
