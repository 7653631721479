const PAGE_COUNT = 12;
const API_LOAD_COUNT = 4;
const LANDSCAPE_CARD_PREVIEW_LENGTH = 3;
const PORTRAIT_CARD_PREVIEW_LENGTH = 2;
const MOBILE_CARD_PREVIEW_LENGTH = 1;
const DESKTOP_CARD_PREVIEW_LENGTH = 4;
const OVERVIEW_PARAGRAPH_LENGTH = 10;
const VIEW_SERVICES_ACCORDION = 3;
const SUCCESS_STORIES_LENGTH = 4;
const LOAD_MORE_STORIES_LENGTH = 16;
const COMPANY_INFORMATION_ACCORDION = 4;
const DEFAULT_ACCORDION_SIZE = 3;
const FEATURED_SUCCESS_STORY_LENGTH = 3;
const NUMBERS = {
  SEVEN_HUNDRED_SIXTY_EIGHT: 768,
  ONE_THOUSAND_FORTY: 1040,
  ONE_THOUSAND_TWENTY_FOUR: 1024,
  SIX_HUNDRED_FOURTY: 640,
};
const DEFAULT_LANGUAGE = 'en-US';
const TRANSLATION_LANGUAGES = [{
  display: 'English',
  key: DEFAULT_LANGUAGE,
},
{
  display: 'Čeština',
  key: 'cs-CZ',
},
{
  display: 'Deutsch',
  key: 'de-DE',
},
{
  display: 'Français',
  key: 'fr-FR',
},
{
  display: 'Español',
  key: 'es-ES',
},
{
  display: 'Italiano',
  key: 'it-IT',
},
{
  display: 'Português',
  key: 'pt-BR',
},
{
  display: '繁體中文',
  key: 'zh-HK',
},
{
  display: '简体中文',
  key: 'zh-CN',
},
{
  display: '한국어',
  key: 'ko-KR',
},
{
  display: '日本語',
  key: 'ja-JP',
}];
module.exports = {
  PAGE_COUNT,
  API_LOAD_COUNT,
  COMPANY_INFORMATION_ACCORDION,
  DEFAULT_ACCORDION_SIZE,
  LANDSCAPE_CARD_PREVIEW_LENGTH,
  PORTRAIT_CARD_PREVIEW_LENGTH,
  MOBILE_CARD_PREVIEW_LENGTH,
  DESKTOP_CARD_PREVIEW_LENGTH,
  OVERVIEW_PARAGRAPH_LENGTH,
  VIEW_SERVICES_ACCORDION,
  LOAD_MORE_STORIES_LENGTH,
  SUCCESS_STORIES_LENGTH,
  NUMBERS,
  FEATURED_SUCCESS_STORY_LENGTH,
  TRANSLATION_LANGUAGES,
  DEFAULT_LANGUAGE,
};
