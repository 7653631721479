import axios from 'axios';
import { ENVIRONMENT, ENVIRONMENT_CONSTANTS } from '../common/constants';

const env = process.env[ENVIRONMENT] || 'dev';

const defaultCountryResponse = 'US';

const errorCase = () => {
  console.warn('akamai location API bad response, using default');
  return defaultCountryResponse; // default if we dont get a country back from a 200 response
};

const localeRender = (response) => {
  if (response.status === 200) {
    const res = response.headers['x-edgescape-location'];
    if (res) {
      return res.split(',')[0].split('=')[1];
    }
    return errorCase();
  }
  return errorCase();
};

const getUserCountry = async () => {
  try {
    const response = await axios.get(ENVIRONMENT_CONSTANTS[env].USER_COUNTRY_SERVICE_URL);
    return localeRender(response);
  } catch (error) {
    return errorCase();
  }
};

export {
  defaultCountryResponse, getUserCountry,
};
