const API_PATH = {
  API_V1: '/api/v1',
  PARTNERS_LISTING: '/partners',
  PARTNERS_FILTER: '/partners/filters',
  PARTNERS_FILTER_STATUS: '/partners/filters-status-new',
  PARTNERS_SUCCESS_STORIES: '/partners/success-stories',
  PARTNER_DETAIL: '/partners/',
};

module.exports = {
  API_PATH,
};
