const { includes, sortBy, uniq } = require('lodash');

const extractContent = (htmlString = '') => htmlString.replace(/<[^>]+>/g, '');

const getData = (filterValues, profileValues) => {
  const mapedValues = {};
  Object.keys(filterValues).forEach((filterValue) => {
    sortBy(uniq(profileValues)).forEach((profileValue) => {
      if (includes(filterValues[filterValue], profileValue)) {
        if (mapedValues[filterValue]) {
          mapedValues[filterValue] = [...mapedValues[filterValue], profileValue];
        } else {
          mapedValues[filterValue] = [profileValue];
        }
      }
    });
  });
  return mapedValues;
};

const getSpecializations = (filterValues, profileValues) => {
  const allSpecializations = {};
  Object.keys(filterValues).forEach((filterValue) => {
    sortBy(profileValues).forEach((profileValue) => {
      if (filterValue === profileValue?.category) {
        if (allSpecializations[filterValue]) {
          allSpecializations[filterValue] = [...allSpecializations[filterValue], profileValue];
        } else {
          allSpecializations[filterValue] = [profileValue];
        }
      }
    });
  });
  return allSpecializations;
};

const humanize = (str) => {
  const frags = str.split('_');
  const humanizeValue = [];
  frags.forEach((value) => {
    humanizeValue.push(value.charAt(0).toUpperCase() + value.slice(1));
  });

  return humanizeValue.join(' ');
};

const getAccreditations = (profileValues) => {
  const convertedAccreditations = {};
  Object.keys(profileValues).forEach((profileValue) => {
    convertedAccreditations[humanize(profileValue)] = profileValues[profileValue];
  });
  return convertedAccreditations;
};

const getFeatureSuccessStories = (stories = []) => {
  const storiesWithImage = [];
  let featuredIndex = 1;
  stories.forEach((story) => {
    if (story.is_featured) {
      storiesWithImage.push({
        display_name: story?.title, id: story?.id, headline: story?.customer_challenge, logo_url: `/support/partners/images/partner-profile/story-image-${featuredIndex}.png`,
      });
      featuredIndex += 1;
    }
  });
  return storiesWithImage;
};

const getAffiliations = (affiliations) => {
  const allAffiliations = [];
  if (affiliations?.length) {
    const customOrder = ['Autodesk Solution Provider', 'Autodesk Learning Partner', 'APS Certified', 'Construction Cloud', 'Autodesk Technology Partner', 'Service Provider Program'];
    sortBy(affiliations, (item) => customOrder.indexOf(item.category)).forEach((affiliation) => {
      allAffiliations.push({
        display_name: affiliation.title,
        headline: affiliation.description,
        logo_url: affiliation.badge,
        eyebrow: affiliation.category,
      });
    });
  }
  return allAffiliations;
};

const getPartnerTranslationData = (data) => {
  const dataArray = [];
  // Extract values from "services"
  Object.keys(data.services).forEach((service) => {
    dataArray.push({ source: service });
    data.services?.[service].forEach((text) => {
      if (text) {
        dataArray.push({ source: text });
      }
    });
  });

  // Extract values from "specializations"
  Object.keys(data.specializations).forEach((specialization) => {
    dataArray.push({ source: specialization });
    data.specializations?.[specialization].forEach((spec) => {
      const { title, category, description } = spec;
      if (title) {
        dataArray.push({ source: title });
      }
      if (category) {
        dataArray.push({ source: category });
      }
      if (description) {
        dataArray.push({ source: description });
      }
    });
  });

  // Extract values from "affiliations"
  data?.affiliations?.forEach((affiliation) => {
    const { display_name: displayName, eyebrow, headline } = affiliation || {};
    if (displayName) {
      dataArray.push({ source: displayName });
    }
    if (eyebrow) {
      dataArray.push({ source: eyebrow });
    }
    if (headline) {
      dataArray.push({ source: headline });
    }
  });

  // Extract values from "solutions"
  Object.keys(data.solutions).forEach((category) => {
    dataArray.push({ source: category });
    data.solutions?.[category].forEach((text) => {
      if (text) {
        dataArray.push({ source: text });
      }
    });
  });

  // Extract values from "accreditations"
  Object.keys(data.accreditations).forEach((accreditation) => {
    dataArray.push({ source: accreditation });
    data.accreditations?.[accreditation].forEach((skill) => {
      const { name, description } = skill || {};
      if (name) {
        dataArray.push({ source: name });
      }
      if (description) {
        dataArray.push({ source: description });
      }
    });
  });

  // Extract values from "successStories"
  data.successStories?.forEach((story) => {
    const { title, customer_challenge: customerChallenge } = story;
    if (title) {
      dataArray.push({ source: title });
    }
    if (customerChallenge) {
      dataArray.push({ source: customerChallenge });
    }
  });

  // Extract values from "featureSuccessStories"
  data.featureSuccessStories?.forEach((featureStory) => {
    const { display_name: displayName, headline } = featureStory;
    if (displayName) {
      dataArray.push({ source: displayName });
    }
    if (headline) {
      dataArray.push({ source: headline });
    }
  });

  // Extract values from "companyInformation"
  Object.keys(data.companyInformation?.['Locations served']).forEach((region) => {
    dataArray.push({ source: region });
    data.companyInformation['Locations served']?.[region].forEach((country) => {
      if (country) {
        dataArray.push({ source: country });
      }
    });
  });

  // Extract values from "companyInformation.Languages"
  data.companyInformation?.Languages?.forEach((language) => {
    if (language) {
      dataArray.push({ source: language });
    }
  });

  // Extract values from "companyInformation.Industries"
  Object.keys(data.companyInformation?.Industries).forEach((industryCategory) => {
    dataArray.push({ source: industryCategory });
    data.companyInformation.Industries?.[industryCategory].forEach((industry) => {
      if (industry) {
        dataArray.push({ source: industry });
      }
    });
  });
  if (data.companyInformation?.['Company size']) {
    dataArray.push({ source: data.companyInformation?.['Company size'] });
  }
  dataArray.push({ source: 'Company size' });
  dataArray.push({ source: 'Locations served' });
  dataArray.push({ source: 'Languages' });
  dataArray.push({ source: 'Industries' });
  const uniqueValues = [];
  const uniqueObjects = [];
  dataArray.filter((obj) => {
    const value = obj.source;
    if (!uniqueValues.includes(value)) {
      uniqueValues.push(value);
      uniqueObjects.push({ source: value });
      return true;
    }
    return false;
  });
  return uniqueObjects;
};

const getFormattedProfileData = (object) => {
  const { filterData, profileData } = object || {};
  const profileObject = {};
  profileObject.services = getData(filterData?.services || {}, profileData?.services || []);
  profileObject.specializations = getSpecializations(filterData?.specializations || {}, profileData?.specializations || []);
  profileObject.solutions = getData(filterData?.solutions || {}, profileData?.solutions || []);
  profileObject.accreditations = getAccreditations(profileData?.accreditations);
  profileObject.successStories = profileData?.success_stories || [];
  profileObject.featureSuccessStories = getFeatureSuccessStories(profileData?.success_stories);
  profileObject.affiliations = getAffiliations(profileData?.affiliations);
  profileObject.companyInformation = {
    'Company size': profileData?.size || '',
    'Locations served': getData(filterData?.locations || {}, profileData?.locations_served || []),
    Languages: profileData?.languages || [],
    Industries: getData(filterData?.industries || {}, profileData?.industries || []),
  };
  return profileObject;
};
module.exports = {
  extractContent,
  getFormattedProfileData,
  getPartnerTranslationData,
  getFeatureSuccessStories,
};
