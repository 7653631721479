// const window = require('global/window');
// eslint-disable-next-line import/no-extraneous-dependencies
const lightTheme = require('@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json');

const modExports = {};
// modExports.FILTER_GROUP_SEPERATOR = '|';
// modExports.FILTER_LIST_SEPERATOR = ',';
modExports.BASENAME = '/support/partners';
modExports.OXYGEN_REDIRECT_URL_KEY = 'autodesk_oxygen_redir_url';

modExports.PRELOADED_STATE_KEY = '__PRELOADED_STATE__';

modExports.DEV_ENV = 'dev';
modExports.QA_ENV = 'qa';
modExports.STAGE_ENV = 'stg';
modExports.PROD_ENV = 'prd';
modExports.MAINTENANCE_MODE = 'MAINTENANCE_MODE';

modExports.LOG_LEVEL = 'LOG_LEVEL';
modExports.LOG_LEVEL_DEBUG = 'debug';
modExports.LOG_LEVEL_INFO = 'info';
modExports.LOG_LEVEL_WARN = 'warn';
modExports.LOG_LEVEL_ERROR = 'error';

modExports.LOGGER_URL = 'LOGGER_URL';
modExports.ENVIRONMENT = 'ENVIRONMENT';
modExports.REQUEST_FLAGS = 'requestFlags';
modExports.IS_PREVIEW = 'isPreview';
modExports.COURSE_API_HOST = 'COURSE_API_HOST';
modExports.COURSE_PREVIEW_API_HOST = 'COURSE_PREVIEW_API_HOST';
modExports.COURSE_PREVIEW_REQUEST_HOST = 'COURSE_PREVIEW_REQUEST_HOST';
modExports.USE_MOCKS = 'useMocks';
modExports.SHARE_PREFIX = 'SHARE_PREFIX';
modExports.HOST = 'HOST';
modExports.CLARUS_LINK = 'CLARUS_LINK';
modExports.LL_PRODUCER_API = 'LL_PRODUCER_API';
modExports.LL_CONSUMER_API = 'LL_CONSUMER_API';
modExports.LIST_API_HOST = 'LIST_API_HOST';
modExports.CERT_API = 'CERT_API';
modExports.SEARCH_API = 'SEARCH_API';
modExports.FILTER_API = 'FILTER_API';
modExports.HTML_MAX_AGE = 'HTML_MAX_AGE';

modExports.COMPONENT_INDEX = 'index';
modExports.COMPONENT_PROFILE = 'profile';
modExports.COMPONENT_SUCCESS_STORIES = 'success-stories';
modExports.COMPONENT_NOTFOUND = 'notFound';

modExports.BANNER_TYPE_INFO = 'info';
modExports.BANNER_TYPE_SUCCESS = 'success';
modExports.BANNER_TYPE_WARNING = 'warning';
modExports.BANNER_TYPE_ERROR = 'error';

modExports.OBJECT_TYPE_QUERY = 'query';

modExports.LEARN_LIVE_ENROLLMENT_HOST = 'LEARN_LIVE_ENROLLMENT_HOST';

modExports.VALID_AWS_ENVS = [modExports.DEV_ENV, modExports.QA_ENV, modExports.STAGE_ENV, modExports.PROD_ENV];
modExports.VALID_LOG_LEVELS = [
  modExports.LOG_LEVEL_DEBUG,
  modExports.LOG_LEVEL_INFO,
  modExports.LOG_LEVEL_WARN,
  modExports.LOG_LEVEL_ERROR,
];

modExports.ERROR_TYPE_404 = '404';
modExports.ERROR_TYPE_XHR = 'XHR';
modExports.ERROR_TYPE_IFRAME = 'IFRAME';
modExports.ERROR_TYPE_BROWSER = 'BROWSER';

modExports.LOCAL_STORAGE_KEY = 'partner-finder.app';
modExports.LOCAL_STORAGE_VIEW_COUNT_KEY = '_uavc';
modExports.LOCAL_STORAGE_ANONYMOUS_ID = 'anonymous';

modExports.UNAUTHMODAL_SHOWN = 'UNAUTHMODAL_SHOWN';

modExports.RETRY_COUNT = 2;

modExports.PKCE_CLIENT_ID = 'PKCE_CLIENT_ID';
modExports.PKCE_ENV = 'PKCE_ENV';
modExports.PARTNER_ENV = 'PARTNER_ENV';
modExports.PARTNER_HOST = 'PARTNER_HOST';
modExports.MS_TRANSLATOR_API_URL = 'MS_TRANSLATOR_API_URL';

modExports.VERSION = 'VERSION';
modExports.BEARER_TOKEN = 'BEARER_TOKEN';
modExports.REACT_APP_SUBS_KEY = 'REACT_APP_SUBS_KEY';
modExports.REACT_APP_TRANSLATIONS_CID = 'REACT_APP_TRANSLATIONS_CID';
modExports.REACT_APP_TRANSLATIONS_SECRET = 'REACT_APP_TRANSLATIONS_SECRET';

/*
 This object is a key/value list of AWS Lambda Environment Variables required for the application.
 The value of each key is an object that contains:
    default (required): default value for this key if it is not present in the env
    validator (optional): a function to validate the value before setting it.  This function should
    return a boolean value.  If false the application will use the default value instead of the value
    that failed validation.
 */
modExports.AWS_ENV_VARS = {
  ENVIRONMENT: {
    default: modExports.DEV_ENV,
    validator: (v) => modExports.VALID_AWS_ENVS.includes(v),
  },
  LOG_LEVEL: {
    default: modExports.LOG_LEVEL_ERROR,
    validator: (v) => modExports.VALID_LOG_LEVELS.includes(v),
  },
  HTML_MAX_AGE: {
    default: '86400',
    validator: (v) => RegExp('^[0-9]$').test(v),
  },
  LOGGER_URL: {},
};

/*
 This object contains Constants who's values are relative to the environment the application is running in.
 */
modExports.ENVIRONMENT_CONSTANTS = {
  dev: {
    ENVIRONMENT: modExports.DEV_ENV,
    LOG_LEVEL: 'debug',
    PKCE_CLIENT_ID: 'Z7VIFrtu4Ey413GgZAna4AqHqJGtyBCiZ9q62ekyL8KgkKDG',
    PARTNER_ENV: 'stg',
    PKCE_ENV: 'stg',
    PARTNER_HOST: 'https://partnersuccesshub.autodesk.com',
    MS_TRANSLATOR_API_URL: 'https://developer.api.autodesk.com/languagetranslation/v1/machine-translate',
    HTML_MAX_AGE: '86400',
    USER_COUNTRY_SERVICE_URL: 'https://akamai.tiqcdn.com/location/location.js',
  },
  qa: {
    ENVIRONMENT: modExports.DEV_ENV,
    LOG_LEVEL: 'debug',
    PKCE_CLIENT_ID: 'Z7VIFrtu4Ey413GgZAna4AqHqJGtyBCiZ9q62ekyL8KgkKDG',
    PARTNER_ENV: 'qa',
    PKCE_ENV: 'stg',
    PARTNER_HOST: 'https://partnersuccesshub.autodesk.com',
    MS_TRANSLATOR_API_URL: 'https://developer.api.autodesk.com/languagetranslation/v1/machine-translate',
    HTML_MAX_AGE: '86400',
    USER_COUNTRY_SERVICE_URL: 'https://akamai.tiqcdn.com/location/location.js',
  },
  stg: {
    ENVIRONMENT: modExports.STAGE_ENV,
    LOG_LEVEL: 'warn',
    PKCE_CLIENT_ID: 'Z7VIFrtu4Ey413GgZAna4AqHqJGtyBCiZ9q62ekyL8KgkKDG',
    PARTNER_ENV: 'stg',
    PKCE_ENV: 'stg',
    PARTNER_HOST: 'https://partnersuccesshub.autodesk.com',
    MS_TRANSLATOR_API_URL: 'https://developer.api.autodesk.com/languagetranslation/v1/machine-translate',
    HTML_MAX_AGE: '86400',
    USER_COUNTRY_SERVICE_URL: 'https://akamai.tiqcdn.com/location/location.js',
  },
  prd: {
    ENVIRONMENT: modExports.PROD_ENV,
    LOG_LEVEL: 'error',
    PKCE_CLIENT_ID: 'pDRYhknnH2EJ8F4CHtyZPUeezdVbjmoU',
    PARTNER_ENV: 'prd',
    PKCE_ENV: 'prd',
    PARTNER_HOST: 'https://partnersuccesshub.autodesk.com',
    MS_TRANSLATOR_API_URL: 'https://developer.api.autodesk.com/languagetranslation/v1/machine-translate',
    HTML_MAX_AGE: '86400',
    USER_COUNTRY_SERVICE_URL: 'https://akamai.tiqcdn.com/location/location.js',
  },
};

// Add ENVIRONMENT_CONSTANTS to AWS_ENV_VARS to allow for overrides
Object.keys(modExports.ENVIRONMENT_CONSTANTS.dev).forEach((key) => {
  modExports.AWS_ENV_VARS[key] = modExports.AWS_ENV_VARS[key] || {};
});

modExports.styleVariables = {
  background: lightTheme.tokens.colors.background.color,
  backgroundPanelColor: lightTheme.tokens.colors['background-panel'].color,
  inkOnBackground: lightTheme.tokens.colors['ink-on-background'].color,
  inkOnBackground60: lightTheme.tokens.colors['ink-on-background-60'].rgb,
  inkOnBackgroundDisabled: lightTheme.tokens.colors['ink-on-background-disabled'].rgb,
  inkSuccess: lightTheme.tokens.colors['ink-success'].color,
  fillWarning: lightTheme.tokens.colors['fill-warning'].color,
  divider: lightTheme.tokens.colors['ink-on-background-60'].rgb,
  fontPrimary: lightTheme.tokens.typography['body-copy-medium'].fontFamily,
  fillSuccess: lightTheme.tokens.colors['fill-success'].color,
  fillError: lightTheme.tokens.colors['fill-error'].color,
  fillEngage: lightTheme.tokens.colors['fill-engage'].color,
  container0BoxShadow: lightTheme.tokens.effects['container-0'].boxShadow,
  detail1Opaque: lightTheme.tokens.colors['detail-1-opaque'].color,
  divider0BottomBoxShadow: lightTheme.tokens.effects['divider-0-bottom'].boxShadow,
  buttonOutlinedFocusBoxShadow: lightTheme.tokens.button.outlined.defaultSize.focus.boxShadow,
  primitivesFontSize: lightTheme.primitives.fontSizes,
  primitivesFontSizeFallbacks: lightTheme.primitives.fontSizeFallbacks,
  primitivesFontWeights: lightTheme.primitives.fontWeights,
  primitivesSpacings: lightTheme.primitives.spacings,
  primitivesColor: lightTheme.primitives.colors,
};
modExports.HEADERS = {
  ACCEPT_CONTENT_TYPE_JSON: 'application/json',
  CONTENT_TYPE: 'content-Type',
};
module.exports = modExports;
