const SuccessStoriesStateInterface = {
  successStories: {
    id: 0,
    title: '',
    is_public: true,
    marketplace_manager: '',
    marketplace_manager_email: '',
    provider_name: '',
    provider_location: '',
    customer_name: '',
    customer_locations: [],
    customer_industries: [],
    customer_service_number: '',
    autodesk_solutions: [],
    business_outcomes: '',
    additional_story_details: '',
    other_professional_services: '',
    conclusion: '',
    customer_challenge: '',
    reason: '',
    project_goals: '',
    solutions: '',
    sustainability: '',
    services_provided: [],
    project_duration: 0,
    project_delivery_date: '',
    legal_terms_accepted: false,
    languages: [],
    story_submission_date: '',
    publication_date: '',
    unique_submission: null,
    reviewed_by: '',
    moderation_state: '',
    authored_by: '',
    link_to_content: null,
    created_at: '',
    updated_at: '',
    provider_id: 0,
    lang: '',
    contact_link: '',
  },
  partnerDetail: {
    name: '',
    id: '',
    display_name: '',
    headline: '',
    contact_link: '',
    banner_url: '',
    success_stories: [],
    moreSuccessStories: [],
  },
  loading: true,
  errors: {
    storyError: false,
    message: '',
  },
  env: 'dev',
  textToTranslate: [],
};
module.exports = {
  SuccessStoriesStateInterface,
};
