const {
  LANDSCAPE_CARD_PREVIEW_LENGTH,
  PORTRAIT_CARD_PREVIEW_LENGTH,
  MOBILE_CARD_PREVIEW_LENGTH,
  DESKTOP_CARD_PREVIEW_LENGTH,
  NUMBERS,
} = require('../constants/app-constants');

const isMobileDevice = () => typeof window !== 'undefined'
    && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    );

const getCradPreviewLength = () => {
  if (
    typeof window !== 'undefined'
        && window?.screen?.availWidth >= NUMBERS.SEVEN_HUNDRED_SIXTY_EIGHT
        && window?.screen?.availWidth <= NUMBERS.ONE_THOUSAND_TWENTY_FOUR
  ) {
    return LANDSCAPE_CARD_PREVIEW_LENGTH;
  }
  if (
    typeof window !== 'undefined'
        && window?.screen?.availWidth >= NUMBERS.SIX_HUNDRED_FOURTY
        && window?.screen?.availWidth <= NUMBERS.SEVEN_HUNDRED_SIXTY_EIGHT
  ) {
    return PORTRAIT_CARD_PREVIEW_LENGTH;
  }
  if (!isMobileDevice()) {
    return DESKTOP_CARD_PREVIEW_LENGTH;
  }
  return MOBILE_CARD_PREVIEW_LENGTH;
};
const getKebabCase = (name, displayName = '', nonBlank = false) => {
  let removeSpclCharacter = name?.replace(/[^\w\s]/gi, '');
  if (!removeSpclCharacter && displayName) {
    removeSpclCharacter = displayName?.replace(/[^\w\s]/gi, '');
  }
  const updatedName = removeSpclCharacter?.toLowerCase()?.split(' ').join('-');
  return nonBlank && !updatedName ? '-' : updatedName;
};

const makeSingleLangTranslatedData = (apiTranslations, requestPayload, language) => {
  const newTranslations = {};
  apiTranslations.forEach((translation, index) => {
    const englishKey = requestPayload[index]?.source;
    if (!newTranslations[language]) {
      newTranslations[language] = {};
    }
    newTranslations[language][englishKey] = translation.translatedText;
  });
  return newTranslations;
};

module.exports = {
  getKebabCase,
  isMobileDevice,
  getCradPreviewLength,
  makeSingleLangTranslatedData,
};
