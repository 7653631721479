import PARTNER_API from './partnerInterceptor';

const CREATE = (url, data, headers) => PARTNER_API({
  method: 'post', url, data, headers,
});
const READ = (url, param, headers) => PARTNER_API({
  method: 'get', url, param, headers,
});
const UPDATE = (url, data, headers) => PARTNER_API({
  method: 'put', url, data, headers,
});
const DELETE = (url, headers) => PARTNER_API({
  method: 'delete', url, headers,
});

export {
  CREATE,
  READ,
  UPDATE,
  DELETE,
};
