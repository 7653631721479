import axios from 'axios';
import { BASENAME } from '../common/constants';

const getTranslatedText = async (text, language) => {
  try {
    const payload = {
      sourceLanguageCode: 'en',
      textToTranslate: text,
      targetLanguageCode: language,
      origin: 'partner-finder',
    };
    const response = await axios.post(`${BASENAME}/translate`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = response;
    const { translations } = data || {};
    return translations;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export default getTranslatedText;
