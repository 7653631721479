import axios from 'axios';
import { HEADERS } from '../common/constants';

const PARTNER_API = axios.create({
  baseURL: '/support/partners',
  timeout: 30000,
  responseType: 'json',
  transformEncoding: 'utf-8',
  validateStatus: (status) => status,
  headers: {
    Accept: HEADERS.ACCEPT_CONTENT_TYPE_JSON,
    'content-Type': HEADERS.CONTENT_TYPE,
  },
});

// Add a request interceptor
PARTNER_API.interceptors.response.use(
  (config) => config, // do something before request is sent
  (error) => Promise.reject(error),
);
PARTNER_API.interceptors.request.use(
  (response) => response,
  (error) => Promise.reject(error),
);
export default PARTNER_API;
